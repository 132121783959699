module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-TR6VY7K2Y3"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Emiliano Lucero Web Dev Portfolio","short_name":"Emiliano Lucero","start_url":"/","background_color":"rgb(240, 240, 240)","theme_color":"rgb(253, 200, 246)","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"74783f8b1aebc1ced263e0ebdc8ed589"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
